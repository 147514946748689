<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="directory">
        <div class="directory-name">
          <span>{{ title }}目录</span>
        </div>
        <div>
          <ul class="directory-list">
            <li
              :class="[
                'directory-item',
                activeItem === directory.id ? 'directory-item-active' : '',
              ]"
              v-for="directory in directoryList"
              :key="directory.id"
              @click="getCourses(directory.id)"
            >
              {{ directory.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="course-list">
        <div
          class="course-item"
          v-for="course in courseList"
          :key="course.id"
          @click="goLearn(course)"
        >
          <div class="cover">
            <img :src="`${OSS_URL}${course.picture}`" alt="" />
          </div>
          <div class="course-introduction">
            <p class="course-name">{{ course.name }}</p>
            <div class="course-percentage">
              <el-progress
                :percentage="
                  parseInt(course.percentage ? course.percentage : 0)
                "
                :show-text="false"
                :stroke-width="14" 
              ></el-progress>
              </div> 
            <div class="course-footer">
              <p>总时长:{{ course.durationTotal }}</p>
              <p>已学习{{ course.percentage ? course.percentage : 0 }}%</p>
            </div>  
            
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="3"></back>
    <!-- <div class="back-group">
      <div class="btn-group">
        <div v-if="showMore" @click="$router.push({ path: '/layout/index' })">
          <img src="@/assets/images/index/home-gray.png" alt="" />
        </div>
        <div
          v-if="showMore"
          @click="$router.push({ path: '/layout/app/WISDOM_CLASSROOM/'+ id })"
        >
          <img src="@/assets/images/index/back-gray.png" alt="" />
        </div>
        <div v-if="showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/close-gray.png" alt="" />
        </div>
        <div v-if="!showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/open-gray.png" alt="" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import menuPop from "@/components/menuPop";
import back from '@/components/back'
import navRouter from "@/components/navRouter";
import { API_MODULE } from "@/request/api";
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CLOUD_CLASSROOM",
  data() {
    return {
      icon: redIcon,
      title: "",
      activeItem: 1,
      directoryList: [],
      courseList: [],
      id: null,
      showMore: false,
    };
  },
  computed: {
    ...mapGetters("skin", ["useSubject", 'useRouterList']),
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
    } else {
      this.icon = blueIcon;
    }
    this.id = this.$route.query.id;
    this.title = this.$route.query.title;

    this.getDirectory();
    this.goByDirectoryId();

    this.useRouterList.find((router, i) => {
      if (!router) return
      if (router.path === this.$route.fullPath) {
        this.useRouterList.splice(i + 1)
      }
    })
    this.setRouterList(this.useRouterList, this.$route.fullPath)
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
      } else {
        this.icon = blueIcon;
      }
    },
  },
  methods: {
    ...mapMutations("skin", ["setRouterList"]),
    // 获取目录
    getDirectory() {
      this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: [this.id],
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.directoryList = res.data.model;
            if (this.directoryList.length > 0) {
              this.getCourses(this.directoryList[0].id)
            }
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },

    getCourses(id) {
      // 将ID放入缓存
      localStorage.setItem('directoryId', id);
      this.activeItem = id;
      this.GET(API_MODULE.GET_COURSES_BY_CATEGORY, { id })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.courseList = res.data;
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    goLearn(course) {
      this.$router.push({
        path: `/layout/learn/${course.id}`,
        query: {
          directoryId: localStorage.getItem('directoryId'),
          percentage: course.percentage,
          columnId: this.id
        }
      })

      // 保存下一级路径
      const path = `/layout/learn/${course.id}?id=${localStorage.getItem('directoryId')}#percentage=${course.percentage}`
      this.useRouterList.push({
        name: course.name,
        path: path
      })
      this.setRouterList(this.useRouterList, path)
    },
    // 根据goByDirectoryId是否为1，来判断需不需要跳转到相应的栏目下
    goByDirectoryId() {
      let id = localStorage.getItem('goByDirectoryId');
      if (id == 1) {
        this.getCourses(localStorage.getItem('directoryId'))
      }
      localStorage.setItem('goByDirectoryId', "");
    }
  },
  components: {
    menuPop,
    navRouter,
    back
  },
};
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.box {
  .router-nav {
    width: 100%;
    height: 0.6rem;
    background-color: #ffffff;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .nav-left {
      display: flex;

      .router-name {
        font-size: 0.16rem;
        @include font_color("font-medium");
        margin-left: 0.1rem;

        .sub-router {
          font-size: 0.16rem;
          color: #cca766;
        }
      }
    }
  }

  .content {
    min-height: 13rem;
    padding: 0.6rem 0 0 0.6rem;
    box-sizing: border-box;
    @include background_color("background-normal");
    display: flex;
    justify-content: space-between;

    .directory {
      width: 4.9rem;
      height: 11.8rem;
      @include background_color("background-light");
      opacity: 1;
      border-radius: 0.05rem;
      padding: 0.65rem 0.48rem;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: scroll;
      margin-right: 0.6rem;

      .directory-name {
        font-size: 0.36rem;
        color: #ffffff;
        opacity: 1;
        padding-bottom: 0.28rem;
        border-bottom-style: solid;
        border-width: 0.01rem;
        font-weight: 600;
        @include border_color("border-witer");
        text-align: left;
      }

      .directory-list {
        list-style: none;
        margin-top: 0.74rem;

        .directory-item {
          margin-bottom: 0.6rem;
          padding-left: 0.14rem;
          box-sizing: border-box;
          text-align: left;
          font-size: 0.36rem;
          min-height: 0.36rem;
          font-weight: 400;
          color: #ffd0d0;
          opacity: 1;
          border-left-style: solid;
          border-width: 0.05rem;
          border-radius: 0.02rem;
          @include border_color("border-witer");
        }

        .directory-item-active {
          font-size: 0.48rem;
          color: #ffffff;
        }
      }
    }

    .course-list {
      height: 11.8rem;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      overflow-x: hidden;
      overflow-y: scroll;

      .course-item {
        width: 4.27rem;
        height: 5.6rem;
        border-style: solid;
        border-width: 0.1rem;
        @include border_color("border-witer");
        border-radius: 0.05rem;
        position: relative;
        margin-right: 0.6rem;
        margin-bottom: 0.6rem;
        box-sizing: border-box;

        .cover {
          width: 4.07rem;
          height: 5.4rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .course-introduction {
          padding: 0.3rem 0.18rem 0.22rem 0.18rem;
          //padding: 0.5rem 0.18rem 0.05rem;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 2.1rem;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 0 0 0.05rem 0.05rem;

          .course-name {
            font-size: 0.39rem;
            line-height: 0.39rem;
            font-weight: bold;
            color: #ffffff;
            // margin-top: 0.1rem;
            margin-bottom: 0.06rem;
            padding-top: 0.2rem;
            text-align: left;
            width: 3.6rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .course-percentage {
            // margin-bottom: 0.1rem;
             margin-bottom: 0.1rem;
          }

          .course-footer {
            display: flex;
            justify-content: space-between;
            font-size: 0.22rem;
            color: #ffffff;
          
          }
        }

        ::v-deep .el-progress-bar__inner {
          @include background_color("background-medium");
        }
      }
    }
  }
}

.back-group {
  z-index: 3005;
  position: fixed;
  right: 0.6rem;
  bottom: 0.8rem;
  /*opacity: 0.8;*/
  .btn-group {
    div {
      margin-bottom: 0.1rem;
    }
  }
  img {
    width: 1.2rem;
  }
}
</style>
