<template>
  <div class="box" :style="boxBg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="tabs">
        <div :class="[
            'tab-item',
            activeIndex === index ? 'active-tab' : 'blur-item',
          ]" v-for="(item, index) in tabs" :key="index" @click="changeTabs(index)">
          <span>{{ item }}</span>
        </div>
      </div>
      <div class="tab-container">
        <div class="course-item" v-for="course in courseList" :key="course.id" @click="
            $router.push({
              path: `/layout/learn/${course.id}`,
            })
          " v-show="activeIndex !== 3">
          <div class="cover">
            <img :src="`${OSS_URL}${course.picture}`" alt="" />
          </div>
          <div class="course-introduction">
            <p class="course-name">{{ course.name }}</p>
            <div class="course-percentage">
              <el-progress :percentage="parseInt(course.percentage)" :show-text="false" :stroke-width="14"></el-progress>
            </div>
            <div class="course-footer">
              <p>
                总时长:{{ course.durationTotal ? course.durationTotal : 0 }}
              </p>
              <p>已学习{{ course.percentage ? course.percentage : 0 }}%</p>
            </div>
            
          </div>
        </div>
        <div class="analysis" v-show="activeIndex === 3">
          <div class="years">
            <div :class="[
                'year',
                activeYear == item ? 'active-year' : 'blur-year',
              ]" v-for="item in years" :key="item" @click="changeYear(item)">
              <span>{{ item }}</span>
            </div>
          </div>
          <div class="year-contaner" :style="bg">
            <div class="year-contaner-column">
              <div class="block">
                <div>{{ yearStatistical.chapterCount }}</div>
                <div>总章节</div>
              </div>
              <div class="block">
                <div>{{ yearStatistical.accomplishCount }}</div>
                <div>完成章节</div>
              </div>
            </div>
            <div class="year-contaner-column">
              <div class="block">
                <div>{{ yearStatistical.picture }}%</div>
                <div>完成率</div>
              </div>
              <div class="block">
                <div>{{ yearStatistical.signinCount }}</div>
                <div>签到数</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="3"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop'
import back from '@/components/back'
import navRouter from '@/components/navRouter'
import { API_MODULE } from '@/request/api'
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const blueBg = require('@/assets/images/classroom/bg-blue.png')
const redBg = require('@/assets/images/classroom/bg.png')

const baseBlueBg = require('@/assets/images/index/bg-blue.png')
const baseRedBg = require('@/assets/images/index/bg.png')
import { mapGetters } from 'vuex'

export default {
  name: 'mine',
  components: {
    menuPop,
    navRouter,
    back
  },
  data() {
    return {
      icon: redIcon,
      tabs: ['在学课程', '已学课程', '收藏课程', '统计分析'],
      activeIndex: 0,
      years: [],
      activeYear: '',
      bg: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgRed: {
        backgroundImage: 'url(' + redBg + ')'
      },
      bgBlue: {
        backgroundImage: 'url(' + blueBg + ')'
      },
      boxBg: {
        backgroundImage: 'url(' + baseRedBg + ')'
      },
      baseRedBg: {
        backgroundImage: 'url(' + baseRedBg + ')'
      },
      baseBlueBg: {
        backgroundImage: 'url(' + baseBlueBg + ')'
      },
      courseList: [],
      // 统计
      statisticalList: [],

      yearStatistical: {}
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon
      this.bg = this.bgRed
      this.boxBg = this.baseRedBg
    } else {
      this.icon = blueIcon
      this.bg = this.bgBlue
      this.boxBg = this.baseBlueBg
    }
    this.getMineCourse()
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon
        this.bg = this.bgRed
        this.boxBg = this.baseRedBg
      } else {
        this.icon = blueIcon
        this.bg = this.bgBlue
        this.boxBg = this.baseBlueBg
      }
    }
  },
  methods: {
    changeTabs(index) {
      this.activeIndex = index
      this.getMineCourse()
    },
    changeYear(year) {
      this.activeYear = year

      this.yearStatistical = this.statisticalList.filter((s) => {
        return s.dataTime == year
      })[0]
    },
    getMineCourse() {
      this.GET(API_MODULE.MINE_COURSE, { state: this.activeIndex + 1 })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            if (this.activeIndex !== 3) {
              this.courseList = res.data
            } else {
              this.statisticalList = res.data
              this.years = this.statisticalList.map((year) => year.dataTime)
              this.changeYear(this.years[0])
            }
          } else {
            console.log(res)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/themes/_handle.scss';

.box {
  min-height: 13.6rem;
  background-size: cover;
  background-repeat: no-repeat;
  .content {
    min-height: 13rem;
    padding: 0.6rem 0 0 0.6rem;
    box-sizing: border-box;
    // @include background_color("background-normal");
    display: flex;

    .tabs {
      width: 4.9rem;

      .tab-item {
        width: 4.9rem;
        height: 1rem;
        text-align: center;
        line-height: 1rem;
        font-size: 0.3rem;
        color: #ffffff;
        border-radius: 0.05rem;
        margin-bottom: 0.4rem;
      }

      .blur-item {
        @include background_color('background-medium');
      }

      .active-tab {
        background-color: #cca766;
      }
    }

    .tab-container {
      height: 11.8rem;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      overflow-x: hidden;
      overflow-y: scroll;
      margin-left: 0.6rem;

      .course-item {
        width: 4.27rem;
        height: 5.6rem;
        border-style: solid;
        border-width: 0.1rem;
        @include border_color('border-witer');
        border-radius: 0.05rem;
        position: relative;
        margin-right: 0.6rem;
        margin-bottom: 0.6rem;
        box-sizing: border-box;

        .cover {
          width: 4.07rem;
          height: 5.4rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .course-introduction {
          // padding: 0.56rem 0.18rem 0.18rem 0.18rem;
          padding: 0.3rem 0.18rem 0.22rem 0.18rem;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 2.1rem;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 0 0 0.05rem 0.05rem;

          .course-name {
            font-size: 0.39rem;
            line-height: 0.39rem;
            font-weight: bold;
            color: #ffffff;
            // margin-bottom: 0.22rem;
            margin-bottom: 0.06rem;
            padding-top: 0.2rem;
            // margin-top: 0.1rem;
            text-align: left;
            width: 3.6rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .course-percentage {
            margin-bottom: 0.1rem;
          }

          .course-footer {
            display: flex;
            justify-content: space-between;
            font-size: 0.22rem;
            color: #ffffff;
          }
        }

        ::v-deep .el-progress-bar__inner {
          @include background_color('background-medium');
        }
      }

      .analysis {
        .years {
          display: flex;

          .year {
            width: 3rem;
            height: 1rem;
            border-radius: 1rem;
            line-height: 1rem;
            text-align: center;
            background: #e03838;
            color: #ffffff;
            font-size: 0.36rem;
          }

          .active-year {
            background-color: #cca766;
          }
        }

        .year-contaner {
          width: 18.7rem;
          height: 8.36rem;
          margin-top: 0.6rem;
          border: 0.05rem solid #f8d7d7;
          border-radius: 0.1rem;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          font-size: 0.36rem;
          color: #ffffff;
          background: #e03838;
          background-size: cover;

          p {
            margin-bottom: 0.32rem;
          }

          .number {
            font-size: 0.56rem;
            color: #ffed0f;
            margin: 0 0.4rem;
          }

          .year-contaner-column {
            display: flex;
            flex-direction: column;

            .block {
              margin-bottom: 1rem;
              border: 0.01rem solid rgb(255, 255, 255);
              padding: 0.4rem;
            }
          }
        }
      }
    }
  }
}
</style>
