<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="content-header">
        <el-popover placement="bottom" width="4rem" trigger="click">
          <div class="pop-content">
            <vue-qrcode
              tag="img"
              :value="WX_URL + `/courseSign?categoryId=${id}&userId=${user.id}`"
              class="qrcode"
            />
            <p>微信扫码签到</p>
          </div>
          <div class="sign-btn" slot="reference">
            <span>签到</span>
          </div>
        </el-popover>
      </div>
      <div class="sign-list">
        <div class="sign-item" v-for="(item, index) in signList" :key="index">
          <div class="sing-item-left">
            <img :src="item.wxAvatarUrl" alt="" />
            <span>{{ item.wxName }}</span>
          </div>
          <div class="sign-item-right">
            <span class="time">{{ item.updateTime }}</span>
            <span>签到成功</span>
          </div>
        </div>
      </div>
    </div>
    <back :pageDepth="6"></back>
  </div>
</template>

<script>
import navRouter from "@/components/navRouter";
import back from "@/components/back";
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const blueBg = require("@/assets/images/index/bg-blue.png");
const redBg = require("@/assets/images/index/bg.png");
import { mapGetters } from "vuex";
import vueQrcode from "@chenfengyuan/vue-qrcode";
import { API_MODULE } from '../../request/api';
export default {
  name: "index",
  components: {
    navRouter,
    back,
    vueQrcode
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: "url(" + redBg + ")"
      },
      bgRed: {
        backgroundImage: "url(" + redBg + ")"
      },
      bgBlue: {
        backgroundImage: "url(" + blueBg + ")"
      },
      id: undefined,
      signList: [],
      user: {},
      timer: null
    };
  },
  methods: {
    getSignList() {
      this.GET(API_MODULE.GET_SIGN_LIST, { category_id: this.id }).then(res => {
        if (res.code == 0 || res.code == 200) {
          this.signList = res.data;
        }
      })
    },
  },
  computed: {
    ...mapGetters("skin", ["useSubject", 'useRouterList'])
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
      this.bg = this.bgRed;
    } else {
      this.icon = blueIcon;
      this.bg = this.bgBlue;
    }
    this.id = this.$route.query.id;
    this.user = JSON.parse(window.localStorage.getItem("USER"));
    this.getSignList()
  },
  mounted() {
    let that = this
    this.timer = setInterval(function () {
      that.getSignList()
    }, 3000);
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
        this.bg = this.bgRed;
      } else {
        this.icon = blueIcon;
        this.bg = this.bgBlue;
      }
    }
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
}
</script>
<style scoped lang="scss">
@import "@/themes/_handle.scss";

.pop-content {
  width: 4rem;
  height: 4.66rem;
  @include background_color("background-medium");
  text-align: center;
  color: #ffffff;
  font-size: 0.36rem;

  img {
    width: 3rem;
    margin-top: 0.56rem;
    margin-bottom: 0.2rem;
  }
}

.box {
  min-height: 13.6rem;
  background-size: cover;
  background-repeat: no-repeat;

  .content {
    padding: 0.6rem;

    .content-header {
      height: 1rem;

      .sign-btn {
        float: right;
        width: 3.2rem;
        height: 1rem;
        background: #cca766;
        opacity: 1;
        border-radius: 0.05rem;
        font-size: 0.31rem;
        color: #ffffff;
        line-height: 1rem;
        text-align: center;
      }
    }

    .sign-list {
      margin-top: 0.4rem;
      height: 10.4rem;
      overflow-x: hidden;
      overflow-y: scroll;

      .sign-item {
        width: 100%;
        height: 1.6rem;
        background: #ffffff;
        border: 0.1rem solid rgba(180, 0, 0, 0.4);
        box-shadow: 0 0.03rem 0.06rem rgba(180, 0, 0, 0.2);
        border-radius: 0.05rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.78rem;
        padding-right: 1rem;
        margin-bottom: 0.6rem;

        .sing-item-left {
          font-size: 0.48rem;
          color: #555555;
          display: flex;
          align-items: center;

          img {
            width: 1rem;
            height: 1rem;
            border-radius: 0.1rem;
            font-weight: bold;
            margin-right: 0.5rem;
          }
        }

        .sign-item-right {
          font-weight: bold;
          font-size: 0.36rem;
          @include font_color("font-medium");

          .time {
            margin-right: 0.2rem;
          }
        }
      }
    }
  }
}
</style>
