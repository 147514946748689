<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="list">
        <div class="item" v-for="item in photoList" :key="item.id">
          <el-image
            :src="`${OSS_URL}${item.pictureVideo}`"
            :alt="item.name"
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
      </div>
      <div class="btns">
        <div>
          <img :src="audio" alt="声音" @click="handleMusic(music)" />
        </div>
      </div>
    </div>
    <back :pageDepth="3"></back>
  </div>
</template>

<script>
import navRouter from "@/components/navRouter";
import back from "@/components/back";
import { API_MODULE } from "@/request/api";
const redAudio = require("@/assets/images/photo/audio.png");
const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const blueAudio = require("@/assets/images/photo/audio-blue.png");
const blueBg = require("@/assets/images/base/bg-blue.png");
const redBg = require("@/assets/images/base/bg.png");
const redPauseAudio = require('@/assets/images/photo/audio-pause.png')
const bluePauseAudio = require('@/assets/images/photo/audio-pause-blue.png')
import { mapGetters } from "vuex";

export default {
  name: "info",
  components: {
    navRouter,
    back,
  },
  data() {
    return {
      audio: redPauseAudio,
      icon: redIcon,
      bg: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgRed: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgBlue: {
        backgroundImage: "url(" + blueBg + ")",
      },
      promise: {},
      synth: null,
      photoList: [],
      srcList: [],
      music: undefined,
      isPause: false
    };
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList"]),
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
      this.audio = redPauseAudio;
      this.bg = this.bgRed;
    } else {
      this.icon = blueIcon;
      this.audio = bluePauseAudio;
      this.bg = this.bgBlue;
    }
    this.music = this.$route.query.music
    this.getDirectory();
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
        this.audio = redPauseAudio;
        this.bg = this.bgRed;
      } else {
        this.icon = blueIcon;
        this.bg = this.bgBlue;
        this.audio = bluePauseAudio;
      }
    },
    isPause(val) {
      if (this.useSubject === "red") {
        this.audio = val ? redAudio : redPauseAudio
      } else {
        this.audio = val ? blueAudio : bluePauseAudio
      }
    }
  },
  methods: {
    read(word) {
      const synth = window.speechSynthesis;
      this.synth = synth;
      const msg = new SpeechSynthesisUtterance();
      msg.text = word;
      msg.lang = "zh-CN";

      function handleSpeak() {
        synth.speak(msg);
      }

      function throttle(fn, delay) {
        let last = 0;
        return function () {
          const now = new Date();
          if (now - last > delay) {
            fn.apply(this, arguments);
            last = now;
          }
        };
      }
      throttle(handleSpeak(), 1000);
    },
    getDirectory() {
      this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: [this.$route.query.id],
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.photoList = res.data.model;
            this.srcList = this.photoList.map(photo => this.OSS_URL + photo.pictureVideo)
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          this.$message(err.msg);
        });
    },
    handleMusic(src) {
      this.isPause = !this.isPause
      if (src) {
        this.playOrPausedAudio(this.OSS_URL + src);
      } else {
        this.$message({ message: '请联系管理员上传音频', type: "error" })
      }
    },
  },
  destroyed() {
    this.destroyAudio();
  },
};
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.box {
  width: 25.6rem;
  height: 13.6rem;
  box-sizing: border-box;
  background-size: cover;

  .content {
    min-height: 13rem;
    padding: 0.6rem 0 0.6rem 0.7rem;
    box-sizing: border-box;

    .list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 4.2rem;
        height: 2.6rem;
        overflow: hidden;
        margin-right: 0.78rem;
        margin-bottom: 0.78rem;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .btns {
      position: fixed;
      top: 2rem;
      right: 0.68rem;

      div {
        margin-bottom: 0.6rem;

        img {
          width: 0.8rem;
        }
      }
    }
  }
}
</style>
