<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="title">
        <span class="sheng" ref="sheng">{{ province }}</span>
        <span class="dot" ref="dot" v-if="method === 'normal'"></span>
        <span class="shi" ref="shi" v-if="method === 'normal'">
          {{ city }}
        </span>
      </div>
      <div class="base-list">
        <div
          class="base-item"
          v-for="item in baseList"
          :key="item.id"
          @click="handleClick(item)"
        >
          <img :src="`${OSS_URL}${item.picture}`" alt="" />
          <p class="base-name">
            <span>{{ item.name }}</span>
          </p>
        </div>
      </div>
    </div>
    <!-- <back :pageDepth="3"></back> -->
    <div class="back-group">
      <div class="btn-group">
        <div v-if="showMore" @click="$router.push({ path: '/layout/index' })">
          <img src="@/assets/images/index/home-gray.png" alt="" />
        </div>
        <div
          v-if="showMore"
          @click="
            $router.push({
              path: `/layout/app/RED_BASE/11?province=${useProvince}`,
            })
          "
        >
          <img src="@/assets/images/index/back-gray.png" alt="" />
        </div>
        <div v-if="showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/close-gray.png" alt="" />
        </div>
        <div v-if="!showMore" @click="showMore = !showMore">
          <img src="@/assets/images/index/open-gray.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navRouter from "@/components/navRouter";
import { API_MODULE } from "../../request/api";
const redIcon = require("@/assets/images/dangHistory/home.png");
const blueIcon = require("@/assets/images/dangHistory/home-blue.png");

const blueBg = require("@/assets/images/base/bg-blue.png");
const redBg = require("@/assets/images/base/bg.png");
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "list",
  components: {
    navRouter,
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgRed: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgBlue: {
        backgroundImage: "url(" + blueBg + ")",
      },
      baseList: [],
      appId: 0,
      area: "",
      province: "",
      city: "",
      name: "",
      method: "",
      showMore: false
    };
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList", "useProvince"]),
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
      this.bg = this.bgRed;
      this.$nextTick(() => {
        this.$refs.sheng.style.color = "#000000";
        this.$refs.dot.style.backgroundColor = "#000000";
        this.$refs.shi.style.color = "#000000";
      });
    } else {
      this.icon = blueIcon;
      this.bg = this.bgBlue;
      this.$nextTick(() => {
        this.$refs.sheng.style.color = "#ffffff";
        this.$refs.dot.style.backgroundColor = "#ffffff";
        this.$refs.shi.style.color = "#ffffff";
      });
    }
    this.method = this.$route.query.method;
    this.appId = this.$route.query.appid;
    if (this.method === 'normal') {
      this.area = this.$route.query.area;
      this.getData();
    } else {
      this.name = this.$route.query.name;
      this.provinceText = this.$route.query.province;
      this.getSearch()
    }
    this.useRouterList.find((router, i) => {
      if (!router) return
      if (router.path.indexOf(this.$route.path) == 0) {
        this.useRouterList.splice(i + 1)
      }
    })
    this.setRouterList(this.useRouterList, this.$route.fullPath)
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
        this.bg = this.bgRed;
        this.$nextTick(() => {
          this.$refs.sheng.style.color = "#000000";
          this.$refs.dot.style.color = "#000000";
          this.$refs.shi.style.color = "#000000";
        });
      } else {
        this.icon = blueIcon;
        this.bg = this.bgBlue;
        this.$nextTick(() => {
          this.$refs.sheng.style.color = "#ffffff";
          this.$refs.dot.style.color = "#ffffff";
          this.$refs.shi.style.color = "#ffffff";
        });
      }
    },
  },
  methods: {
    ...mapMutations("skin", ["setRouterList"]),
    getData() {
      this.GET(API_MODULE.SEARCH_MAP_BY_APP_AND_AREA, {
        appId: this.appId,
        city: this.area,
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.baseList = res.data.model;
            this.province = this.baseList[0].province
            this.city = this.baseList[0].city
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    handleClick(item) {
      this.$router.push({
        path: '/layout/baseInfo',
        query: { id: item.id },
      })
      // 保存下一级路径
      const path = `/layout/baseInfo?id=${item.id}`
      this.useRouterList.push({
        name: item.name,
        path: path
      })
      this.setRouterList(this.useRouterList, path)
    },
    getSearch() {
      this.GET(this.API_MODULE.SEARCH_MAP_BY_APP_AND_AREA, {
        appId: this.appId,
        name: this.name,
        province: this.provinceText
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.baseList = res.data.model;
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    }
  },
};
</script>

<style scoped lang="scss">
.box {
  width: 25.6rem;
  height: 13.6rem;
  box-sizing: border-box;

  .content {
    padding-top: 0.8rem;
    padding-left: 0.6rem;

    .title {
      text-align: left;
      display: flex;
      align-items: center;

      .sheng {
        font-size: 0.48rem;
        font-weight: 900;
        color: #ffffff;
      }

      .dot {
        display: inline-block;
        width: 0.05rem;
        height: 0.05rem;
        border-radius: 50%;
        background-color: #ffffff;
        margin-left: 0.4rem;
        margin-right: 0.1rem;
      }

      .shi {
        font-size: 0.36rem;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .base-list {
      margin-top: 0.4rem;
      display: flex;
      flex-wrap: wrap;

      .base-item {
        width: 4.27rem;
        height: 3.1rem;
        background: rgba(0, 0, 0, 0);
        border: 0.1rem solid #ffffff;
        box-shadow: 0 0.05rem 0.1rem rgba(126, 0, 0, 0.55);
        border-radius: 0.05rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-right: 0.6rem;
        margin-bottom: 0.6rem;
        box-sizing: border-box;

        img {
          width: 4.07rem;
          height: 2.9rem;
        }

        .base-name {
          width: 4.07rem;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          height: 0.8rem;
          border-radius: 0 0 0.05rem 0.05rem;
          background-color: rgba(0, 0, 0, 0.5);
          color: #ffffff;
          font-size: 0.32rem;
          line-height: 0.8rem;
          text-align: left;
          padding: 0 0.2rem;
          box-sizing: border-box;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.back-group {
  z-index: 3005;
  position: fixed;
  right: 0.6rem;
  bottom: 0.8rem;
  /*opacity: 0.8;*/
  .btn-group {
    div {
      margin-bottom: 0.1rem;
    }
  }
  img {
    width: 1.2rem;
  }
}
</style>
