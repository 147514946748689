<template>
  <div>
    <div class="box" :style="login">
      <div class="title">
        <img src="@/assets/images/login/login-title.png" style="width: 9.3rem; height: 1.04rem" alt="" />
      </div>
      <div class="content">
        <div class="item">
          <img :src="uIcon" alt="" class="icon-user" />
          <input v-model="loginForm.account" type="text" placeholder="请输入登录账号" @keyup.enter="userLogin" :class="[isRed ? 'inputRed redBorder' : 'inputBlue blueBorder']" />
        </div>
        <div class="item password">
          <img :src="pIcon" alt="" class="icon-user" />
          <input v-model="loginForm.password" type="password" @keyup.enter="userLogin" placeholder="请输入密码" :class="isRed ? 'inputRed redBorder' : 'inputBlue blueBorder'" />
        </div>
        <el-button class="login-btn" @click="userLogin" :loading="loading">
          <span>登 录</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
import BrowserType from '@/utils/user-agent.js'
import { mapGetters } from 'vuex'
const userIcon = require('@/assets/images/login/icon-user.png')
const pwdIcon = require('@/assets/images/login/icon-password.png')
const userBlueIcon = require('@/assets/images/login/icon-user-blue.png')
const pwdBlueIcon = require('@/assets/images/login/icon-password-blue.png')
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        account: '',
        password: '',
        snCode: '',
        grantType: 'screen',
        tenant: ''
      },
      loading: false,
      login: {
        backgroundImage: 'url(' + require('@/assets/images/login/login-bg1.png') + ')'
      },
      equipmentName: '',
      equipmentLogo: '',
      userAgent: {},
      uIcon: userIcon,
      pIcon: pwdIcon,
      isRed: true,
      isd: null
    }
  },
  methods: {
    async userLogin() {
      if (
        this.loginForm.account == '' ||
        this.loginForm.password == '' ||
        this.loginForm.account == null ||
        this.loginForm.password == null
      ) {
        this.$message.error('账号和密码不可为空')
        return
      }
      if (!this.isd) {
        if (this.userAgent.system === 'android' || this.userAgent.system == undefined) {
          this.handleDevice()
        }

        if (this.userAgent.system === 'windows') {
          this.handleAgent()
        }
      } else {
        this.loginForm.snCode = '0000000000'
      }

      if (this.loginForm.snCode) {
        // 获取用户
        const res = await this.POST(this.API_MODULE.SEARCH_USER, {
          account: this.loginForm.account
        })
        if (res.code != 0 && res.code != 200) {
          this.$message.error(res.msg)
          return
        }
        // 加密 tenant
        this.loginForm.tenant = Base64.encode(res.data.tenantCode)
        const tenantName = res.data.tenantName
        const equipmentName = res.data.equipmentName
        const equipmentLogo = res.data.equipmentLogo
        // 验证用户是否能登录
        await this.POST(this.API_MODULE.VERIFY_LOGIN, this.loginForm)
          .then((res) => {
            if (res.code == 0 || res.code == 200) {
              // 保存token
              this.$store.commit('SET_LOGIN_TOKEN', res.data.token)
              window.sessionStorage.setItem('TOKEN', res.data.token)
              window.localStorage.setItem('TOKEN', res.data.token)
              window.localStorage.setItem('REFRESH_TOKEN', res.data.refreshToken)
              window.localStorage.setItem('TENANT', this.loginForm.tenant)
              window.localStorage.setItem('TIME', res.data.expiration)
              window.sessionStorage.setItem('REFRESH_TIME', new Date().getTime())
              window.localStorage.setItem(
                'USER',
                JSON.stringify({
                  id: res.data.userId,
                  account: res.data.account,
                  name: res.data.name,
                  tenantName: tenantName,
                  avatar: res.data.avatar,
                  workDescribe: res.data.workDescribe,
                  equipmentLogo: equipmentLogo,
                  equipmentName: equipmentName
                })
              )

              // 登录
              this.POST(this.API_MODULE.LOGIN, this.loginForm)
                .then((res) => {
                  if (res.code == 0 || res.code == 200) {
                    window.localStorage.setItem('EQUIPMENT', JSON.stringify(res.data))
                    this.loading = false
                    this.$router.push({ path: '/layout/index' })
                  } else {
                    this.loading = false
                    this.$message(res.msg)
                  }
                })
                .catch(() => {
                  this.landing = false
                })
            } else {
              this.loading = false
              this.$message(res.msg)
            }
          })
          .catch(() => {
            this.landing = false
          })
      }
    },
    handleAgent() {
      if (this.SNCODE_STATRUS == 1) {
        // 这两个方法是在index.html 引入的js文件里的
        // eslint-disable-next-line no-undef
        init_qt()
        // eslint-disable-next-line no-undef
        GetCpuID()
        this.loginForm.snCode = document.getElementById('cpu_id').value
      } else {
        this.loginForm.snCode = ''
      }
    },
    handleDevice() {
      if (this.SNCODE_STATRUS == 1) {
        window.androidAPI.callAndroidMethod('getDeviceId', '')
        this.loginForm.snCode = document.getElementById('device_id').value
      } else {
        this.loginForm.snCode = ''
      }
    }
  },
  created() {
    //判断当前选择的是哪个主题,设置选中
    if (this.useSubject === 'red') {
      this.isRed = true
      this.uIcon = userIcon
      this.pIcon = pwdIcon
    } else {
      this.isRed = false
      this.uIcon = userBlueIcon
      this.pIcon = pwdBlueIcon
    }
    if (this.$route.query.isd) {
      this.isd = this.$route.query.isd.replace('/', '')
      window.localStorage.setItem('isd', this.isd)
    }
  },
  mounted() {
    this.userAgent = BrowserType()
    if (!this.isd) {
      if (this.userAgent.system === 'android' || this.userAgent.system == undefined) {
        this.handleDevice()
      }
      if (this.userAgent.system === 'windows') {
        this.handleAgent()
      }
    } else {
      this.loginForm.snCode = '0000000000'
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject'])
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 25.6rem;
  min-height: 14.4rem;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  input::placeholder {
    outline: none;
  }

  .inputRed::placeholder {
    color: #b40000;
  }

  .inputBlue::placeholder {
    color: #1e37ca;
  }

  input:focus {
    outline: none;
  }

  .title {
    position: absolute;
    top: 1.87rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .content {
    width: 9rem;
    height: 6.1rem;
    background: #ffffff;
    box-shadow: 0.03rem 0.04rem 0.07rem rgba(126, 0, 0, 0.55);
    opacity: 1;
    border-radius: 0.1rem;
    position: absolute;
    top: 4.16rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.9rem 1rem 1.05rem 1rem;
    box-sizing: border-box;

    .item {
      width: 7rem;
      height: 1.05rem;
      display: flex;
      align-items: center;
      background: #f0f0f0;
      opacity: 1;
      border-radius: 0.05rem;
      padding: 0.3rem 0.37rem;
      box-sizing: border-box;
      margin-bottom: 0.4rem;

      .icon-user {
        width: 0.32rem;
        margin-right: 0.36rem;
      }

      input {
        width: 4rem;
        height: 0.35rem;
        border: none;
        font-size: 0.3rem;
        padding-left: 0.57rem;
        background: #f0f0f0;
      }

      .redBorder {
        border-left: 0.02rem solid #b40000;
        color: #b40000;
      }

      .blueBorder {
        border-left: 0.02rem solid #1e37ca;
        color: #1e37ca;
      }
    }
    .password {
      margin-bottom: 0.6rem;
    }
    .login-btn {
      width: 7rem;
      height: 1.05rem;
      background: #cca766;
      opacity: 1;
      border-radius: 0.05rem;
      text-align: center;
      font-size: 0.35rem;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
