<template>
  <div>
    <div class="box" :style="bg">
      <nav-router :routerList="useRouterList">
        <img :src="icon" alt="" />
      </nav-router>
      <div class="content">
        <div class="title">{{ content.name }}</div>
        <div class="context">
          <div v-html="content.remark"></div>
        </div>
      </div>
    </div>
    <back :pageDepth="4"></back>
  </div>
</template>

<script>
import navRouter from "@/components/navRouter";
import back from "@/components/back";
import { API_MODULE } from "@/request/api";

const redIcon = require("@/assets/images/dangHistory/home.png");
const blueIcon = require("@/assets/images/dangHistory/home-blue.png");
const blueBg = require("@/assets/images/base/bg-blue.png");
const redBg = require("@/assets/images/base/bg.png");
import { mapGetters } from "vuex";

export default {
  name: "info",
  components: {
    navRouter,
    back,
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgRed: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgBlue: {
        backgroundImage: "url(" + blueBg + ")",
      },
      content: undefined
    };
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList"]),
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
      this.bg = this.bgRed;
    } else {
      this.icon = blueIcon;
      this.bg = this.bgBlue;
    }

    this.getContent();
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
        this.bg = this.bgRed;
      } else {
        this.icon = blueIcon;
        this.bg = this.bgBlue;
      }
    },
  },
  methods: {
    getContent() {
      this.GET(API_MODULE.GET_COURSE, {
        id: this.$route.query.id,
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.content = res.data;
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.box {
  width: 25.6rem;
  height: 13.6rem;
  box-sizing: border-box;

  .content {
    width: 22.8rem;
    height: 12.4rem;
    background: #ffffff;
    opacity: 1;
    border-radius: 0.2rem 0.2rem 0 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0.6rem;
    box-sizing: border-box;
    margin: 0.6rem auto 0 auto;
    text-align: left;

    .title {
      font-size: 0.42rem;
      font-weight: bold;
      @include font_color("font-medium");
    }

    .context {
      margin-top: 0.4rem;
      font-size: 0.28rem;
      color: #555555;
      text-align: justify;
      line-height: 1;

      span,
      p,
      div {
        line-height: 3;
      }
    }
  }
}
</style>
