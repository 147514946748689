<template>
  <div>
    <div class="nav">
      <div class="nav-left">
        <div @click="dialogUserInfo = true">
          <img
            v-if="user.equipmentLogo != '' && user.equipmentLogo != null"
            :src="`${OSS_URL}${user.equipmentLogo}`"
            alt=""
            class="icon-user"
          />
          <img
            v-else
            src="@/assets/images/index/dh.png"
            alt=""
            class="icon-user"
          />
          <span class="name">{{ user.name }}</span>
        </div>
        <div class="line"></div>
        <div class="introduction">
          <div>
            <!-- <img
              src="@/assets/images/index/danghui.png"
              alt=""
              class="danghui"
            /> -->
          </div>
          <div class="name">
            <p class="name-cn">{{ user.equipmentName }}</p>
            <p class="name-en"></p>
          </div>
        </div>
      </div>
      <div class="nav-right">
        <div class="date">{{ date }}</div>
        <div @click="$router.push({ path: '/today' })" class="img-box">
          <img
            src="@/assets/images/index/history-today.png"
            alt=""
            class="history-today"
          />
        </div>
        <div>
          <div class="change-color" @click="drawer = true">换肤</div>
        </div>
        <div class="close-doc" v-if="isWin" @click="handleClose">
          <img
            src="@/assets/images/index/close-doc.jpg"
            alt="关闭系统"
            class="close-icon"
          />
        </div>
      </div>
    </div>
    <el-drawer
      title="换肤"
      :visible.sync="drawer"
      size="30%"
      :with-header="false"
    >
      <div class="drawer">
         <!-- v-if="!isWin" -->
        <div class="clear-btn" @click="handleClear">
          <span>清除缓存</span>
        </div>
        <div class="use-title">换肤</div>
        <div class="list">
          <div class="item">
            <div>
              <img src="@/assets/images/index/red.png" alt="" />
              <p class="menu-name">红色经典</p>
              <div
                :class="['use-btn', selectNum === 0 ? 'use-btn-active' : '']"
              >
                <span v-if="selectNum === 0">使用中</span>
                <span v-else @click="theme(0)">立即使用</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div>
              <img src="@/assets/images/index/blue.png" alt="" />
              <p class="menu-name">蓝色经典</p>
              <div
                :class="['use-btn', selectNum === 1 ? 'use-btn-active' : '']"
              >
                <span v-if="selectNum === 1">使用中</span>
                <span v-else @click="theme(1)">立即使用</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <div class="dialog" v-if="dialogUserInfo">
      <div class="dialog-mask" @click="dialogUserInfo = false"></div>
      <div class="dialog-box">
        <div class="dialog-header">
          <span>绑定信息</span>
          <span @click="dialogUserInfo = false">X</span>
        </div>
        <div class="dialog-content">
          <div class="dialog-info">
            <div class="dialog-info-row">
              <div>租户：</div>
              <div class="dialog-info-value">{{ user.tenantName }}</div>
            </div>
            <div class="dialog-info-row">
              <div>账号：</div>
              <div class="dialog-info-value">{{ user.account }}</div>
            </div>
            <div class="dialog-info-row">
              <div>姓名：</div>
              <div class="dialog-info-value">{{ user.name }}</div>
            </div>
            <div @click="logout" class="logout">
              <span>退出登录</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <keep-alive :include="keepAliveComponents">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { formatTimeToStr } from '@/utils/date.js'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { } from 'vuex'
import BrowserType from '@/utils/user-agent.js'
export default {
  name: 'index',
  data() {
    return {
      date: formatTimeToStr(Date.parse(new Date())),
      drawer: false,
      selectNum: 0,
      dialogUserInfo: false,
      user: {},
      isWin: false
    }
  },
  computed: {
    ...mapState(['keepAliveComponents']),
    ...mapGetters('skin', ['useSubject'])
  },
  created() {
    //判断当前选择的是哪个主题,设置选中
    if (this.useSubject === 'red') {
      this.selectNum = 0
    } else {
      this.selectNum = 1
    }
    this.getUserInfo()
  },
  methods: {
    ...mapMutations('skin', ['setSubject', 'setRouterList']),
    //换主题
    theme(type) {
      // this.$store.commit("upDate", { themeType: type });
      this.selectNum = type
      if (this.selectNum === null) {
        return false
      }
      if (this.selectNum === 0) {
        window.document.documentElement.setAttribute('data-theme', 'light')
        this.setSubject('red')
      } else if (this.selectNum === 1) {
        window.document.documentElement.setAttribute('data-theme', 'blue')
        this.setSubject('blue')
      }
    },
    selectMenu(num) {
      this.selectNum = Number(num)
    },
    // 获取用户信息
    getUserInfo() {
      this.user = JSON.parse(window.localStorage.getItem('USER'))
    },
    // 退出登录
    logout() {
      this.POST(this.API_MODULE.LOGOUT, {
        userId: this.user.id
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            window.localStorage.removeItem('USER')
            window.localStorage.removeItem('REFRESH_TOKEN')
            window.localStorage.removeItem('TENANT')
            window.localStorage.removeItem('TOKEN')
            window.localStorage.removeItem('TIME')
            this.setRouterList([])
            window.sessionStorage.removeItem('TOKEN')
            this.$store.commit('SET_LOGIN_TOKEN', null)
            this.$router.push('/')
            this.dialogUserInfo = false
          } else {
            this.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleClose() {
      // eslint-disable-next-line no-undef
      CloseApp()
    },
    handleClear() {

      this.$confirm('此操作将清除缓存, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.androidAPI.callAndroidMethod('androidClearCache', '')
        this.$message({
          type: 'success',
          message: '清除成功!'
        });
      }).catch(() => { });

    }
  },

  mounted() {
    //设置当前主题
    this.$nextTick(() => {
      if (this.selectNum === 0) {
        window.document.documentElement.setAttribute('data-theme', 'light')
      } else if (this.selectNum === 1) {
        window.document.documentElement.setAttribute('data-theme', 'blue')
      }
    })

    this.timer = setInterval(() => {
      this.date = formatTimeToStr(Date.parse(new Date()))
    }, 1000)
    window.document.documentElement.setAttribute('data-theme', 'light')

    this.userAgent = BrowserType()
    if (this.userAgent.system === 'windows') {
      this.isWin = true
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer) // 在Vue实例销毁前，清除我们的定时器
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.nav {
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*background-color: #E03838;*/
  @include background_color("background-medium");
  @include font_color("font-witer");
  padding: 0 0.4rem;

  .nav-left {
    display: flex;
    align-items: center;
    width: 19rem;
    height: 7rem;
    .name {
      font-size: 0.18rem;
    }
    .icon-user {
      width: 0.65rem;
      height: 0.6rem;
      margin-right: 0.2rem;
      vertical-align: middle;
    }

    .danghui {
      width: 0.28rem;
      margin-bottom: 0.05rem;
    }

    .line {
      height: 0.2rem;
      margin: 0 0.2rem;
      float: left;
      width: 0.01rem;
      height: 0.2rem;
      background: #ffffff;
      // @include border_color("border-witer");
    }

    .introduction {
      display: flex;
      align-items: center;
      margin-bottom: -0.1rem;  
      .name {
        margin-left: 0.12rem;
        text-align: left;
        &-cn {
          font-size: 0.2rem;
          @include font_color("font-witer");
          /* line-height: 0.2rem; */
          margin-bottom: 0.1rem;
        }

        &-en {
          font-size: 0.12rem;
          @include font_color("font-witer");
          line-height: 0.12rem;
        }
      }
    }
  }

  .nav-right {
    display: flex;
    align-items: center;

    .date {
      font-size: 0.18rem;
    }

    .change-color {
      width: 0.6rem;
      height: 0.3rem;
      line-height: 0.3rem;
      @include background_color("font-witer");
      opacity: 1;
      border-radius: 0.05rem;
      font-size: 0.14rem;
      @include font_color("font-btn");
    }

    .img-box {
      height: 0.55rem;
    }

    .history-today {
      width: 2.3rem;
    }
    .close-doc {
      cursor: pointer;
      margin-left: 0.4rem;
    }
    .close-icon {
      width: 0.4rem;
    }
  }
}

.modal-content {
  height: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font_color("font-medium");
}

.logout {
  width: 2.4rem;
  height: 0.4rem;
  background: #cca766;
  opacity: 1;
  border-radius: 0.05rem;
  margin: 0 auto;
  line-height: 0.4rem;
  color: #ffffff;
}

.drawer {
  height: 100%;
  padding: 0.7rem;
  position: relative;
  @include background_color("background-high");

  .clear-btn {
    position: absolute;
    right: 1.4rem;
    width: 1.8rem;
    height: 0.6rem;
    background: #cca766;
    border-radius: 0.3rem;
    color: #ffffff;
    font-size: 0.28rem;
    line-height: 0.6rem;
    text-align: center;
  }

  .use-title {
    top: 0.9rem;
    width: 0.76rem;
    height: 0.37rem;
    font-size: 0.36rem;
    color: #ffffff;
    margin-bottom: 0.93rem;
    font-weight: 900;
  }

  .list {
    display: flex;
    /*align-items: center;*/
    justify-content: space-between;
    margin-bottom: 0.84rem;

    .item {
      width: 3.2rem;
      height: 3.2rem;
      display: flex;
      align-items: center;
      /*flex-wrap: wrap;*/
      justify-content: center;
      border-radius: 0.1rem;
      /*background-color: #ffffff;*/
      /*opacity: 1;*/
      color: #ffffff;

      .use-btn {
        width: 1.8rem;
        height: 0.6rem;
        background: #cca766;
        border-radius: 0.3rem;
        color: #ffffff;
        font-size: 0.28rem;
        line-height: 0.6rem;
        text-align: center;
        margin-top: 0.15rem;
      }
      img {
        width: 1.8rem;
      }

      .use-btn-active {
        background: #d9d9d9;
      }
    }

    .menu-name {
      font-size: 0.36rem;
    }

    .item-active {
      background-color: #ffffff;
      opacity: 0.8;
      border-radius: 0.1rem;
      @include font_color("font-medium");
    }
  }
}

.dialog {
  .dialog-mask {
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .dialog-box {
    width: 9.34rem;
    height: 6rem;
    border-radius: 0.1rem;
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;

    .dialog-header {
      height: 0.8rem;
      width: 100%;
      background: #cca766;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.54rem;
      font-size: 0.26rem;
      color: #ffffff;
      box-sizing: border-box;
    }

    .dialog-content {
      font-size: 0.36rem;
      @include font_color("font-medium");
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      text-align: left;
      padding: 0.6rem 0.4rem 0rem 0.4rem;

      .dialog-info {
        .dialog-info-row {
          margin-bottom: 0.4rem;
          display: flex;
          align-items: center;
          .dialog-info-value {
            flex: 8;
          }
        }
      }
      .logout {
        width: 2.4rem;
        height: 0.7rem;
        background-color: #cca766;
        text-align: center;
        line-height: 0.7rem;
        color: #ffffff;
        font-size: 0.28rem;
        margin-top: 0.3rem;
      }
    }
  }
}
</style>
