<template>
  <div class="box">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="content-left">
        <div class="video-list">
          <div class="video-box">
            <div
              :class="[
                'video-item',
                activeItem === index ? 'active-item' : 'blur-item',
              ]"
              v-for="(item, index) in chapterList"
              :key="item.id"
              @click="changeItem(item, index)"
            >
              <div class="video-item-left">
                <img src="@/assets/images/classroom/play.png" alt="" />
                <span>{{ item.name }}</span>
              </div>

              <div>
                <span class="time">{{ item.videoDuration }}</span>
                <br />
                <span class="wacth">{{ item.isAccomplish == 1 ? "(已观看)" : "" }}</span>
              </div>
              <!-- <div v-if="item.isAccomplish" class="video-completed">
                <img src="@/assets/images/classroom/completed.png" alt="" />
              </div> -->
            </div>
          </div>
        </div>
        <div class="signin-btn" @click="handleSign">
          <span>学员签到</span>
        </div>
      </div>
      <div class="content-right">
        <div class="title">
          <div>
            <span> {{ chapter.name }}</span>
          </div>
          <div class="time">
            <!-- <span @click="updateCurriculum">学时：{{ chapter.studyDuration }}</span> -->
          </div>
        </div>
        <div class="video">
          <div id="mse"></div>
        </div>
        <!-- 因后台无法添加课程介绍，隐藏 -->
        <!-- <div class="introduction">
          <p>课程介绍:</p>
          <span v-html="chapter.subjoinText"> </span>
        </div> -->
        <div>
          <!-- <img src="@/assets/images/classroom/open.png" alt="" /> -->
        </div>
      </div>
    </div>
    <menu-pop :activeItem="3"></menu-pop>
    <back :pageDepth="5"></back>
  </div>
</template>

<script>
import menuPop from "@/components/menuPop";
import back from "@/components/back";
import navRouter from "@/components/navRouter";
import BrowserType from "@/utils/user-agent.js";
import Player from "xgplayer";
import { API_MODULE } from "@/request/api";
const redIcon = require("@/assets/images/dangHistory/home.png");
const blueIcon = require("@/assets/images/dangHistory/home-blue.png");
import { mapGetters, mapMutations } from "vuex";

// import downloadUrlFile from "@/utils/download-blob.js"

export default {
  name: "mine",
  components: {
    menuPop,
    navRouter,
    back,
  },
  data() {
    return {
      icon: redIcon,
      activeItem: 0,
      chapterList: [],
      player: null,
      chapter: {},
      // 视频当前播放时间
      currentTime: "0",
      // 课程id
      contentId: "",
      showMore: false,
      urlId: "",
      videoUrl: "",
      picture: "",
      localUrl: "",
      suffix: "",
      playUrl: "",
      count: 0,
      isCache: false,
    };
  },
  computed: {
    ...mapGetters("skin", ["useSubject", "useRouterList"]),
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
    } else {
      this.icon = blueIcon;
    }
    this.init();

    this.useRouterList.find((router, i) => {
      if (!router) return;
      if (router.path === this.$route.fullPath) {
        this.useRouterList.splice(i + 1);
      }
    });
    this.setRouterList(this.useRouterList, this.$route.fullPath);
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
      } else {
        this.icon = blueIcon;
      }
    },
  },
  methods: {
    ...mapMutations("skin", ["setRouterList"]),
    //大屏更新课程内容观看进度
    updateCurriculum() {
      // 将纯秒数 转化为 时:分:秒
      let value = localStorage.getItem("currentTime").split(".")[0];
      let result = parseInt(value);
      let h =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);

      let res = "";
      res += `${h}:`;
      res += `${m}:`;
      res += `${s}`;
      this.POST(API_MODULE.UPDATE_CURRICULUM_CHAPTER, {
        contentId: this.contentId,
        studyDuration: res,
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            console.log(res);
          } else {
            this.$message({ message: res.msg, type: "success" });
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    goBack() {
      this.updateCurriculum();
      this.$router.go(-1);
    },
    goHome() {
      this.updateCurriculum();
      this.$router.push({ path: "/layout/index" });
    },
    changeItem(item, index) {
      this.contentId = item.id;
      this.urlId = item.texts;
      this.activeItem = index;
      // 切换章节
      this.chapter = item;

      // 切换视频源
      this.videoUrl = this.OSS_URL + item.pictureVideo;
      this.suffix = this.videoUrl.substring(this.videoUrl.lastIndexOf("."));
      let localUrl = "-";
      if (this.userAgent.system === "android") {
        localUrl = window.androidAPI.callAndroidMethod(
          "androidCacheVideoUrl",
          this.urlId + this.suffix
        );
      }
      if (localUrl === "" || localUrl === "-") {
        this.playUrl = this.videoUrl;
      } else {
        this.playUrl = localUrl;
      }
      this.picture = this.OSS_URL + item.picture;
      this.player.poster = item.picture ? this.picture : "";

      if (this.count === 0) {
        this.player.start(this.playUrl);
      } else {
        this.player.src = this.playUrl;
      }
      this.player.play();
      this.count++;
    },

    initVideo() {
      let that = this;
      this.player = new Player({
        id: "mse",
        url: this.playUrl,
        playsinline: true,
        whitelist: [""],
        width: "1680",
        height: "852",
        volume: 1,
        poster: this.picture,
        keyShortcut: "on",
        autoplay: false,
        // "ignores": ["replay"],
        // fitVideoSize: 'auto',
        controlsList: ["nodownload", "nofullscreen", "noremoteplayback"],
        currentTime: that.currentTime,
        rotate: false,
        ignores: ["fullscreen"],
        cssFullscreen: true,
        lang: "zh-cn",
        // download: true
      });
      this.player.on("timeupdate", function () {
        localStorage.setItem("currentTime", this.currentTime);
      });
      this.player.on("ended", function () {
        that.updateCurriculum();
        // 启动缓存
        if (that.isCache) {
          // 监听结束，视频播放结束就下载视频
          that.suffix = that.videoUrl.substring(that.videoUrl.lastIndexOf("."));
          console.log("下载视频:" + that.urlId + that.suffix);
          if (
            that.videoUrl.indexOf("127.0.0.1") == -1 &&
            that.userAgent.system === "android"
          ) {
            window.androidAPI.callAndroidMethod(
              "androidDownloadVideoUrl",
              `{"url":"${that.videoUrl}", "name": "${
                that.urlId + that.suffix
              }"}`
            );
          }
        }
      });

      this.player.on("play", function () {
        if (that.videoUrl !== that.player.src) {
          that.player.src = that.playUrl;
          that.player.play();
        }
      });
    },
    async init() {
      const res = await this.GET(API_MODULE.GET_COURSE_CHAPTERS, {
        id: this.$route.query.id,
      });

      this.chapterList = res.data;
      this.videoUrl = this.chapterList[0].pictureVideo
        ? this.OSS_URL + this.chapterList[0].pictureVideo
        : "";
      this.picture = this.chapterList[0].picture
        ? this.OSS_URL + this.chapterList[0].picture
        : "";
      this.contentId = this.chapterList[0].id ? this.chapterList[0].id : "";
      this.urlId = this.chapterList[0].texts ? this.chapterList[0].texts : "";
      if (this.isCache) {
        // 播放时查看本地是否存在，动态切换视频源
        this.suffix = this.videoUrl.substring(this.videoUrl.lastIndexOf("."));
        let localUrl = "-";
        if (this.userAgent.system === "android") {
          localUrl = await window.androidAPI.callAndroidMethod(
            "androidCacheVideoUrl",
            this.urlId + this.suffix
          );
        }

        if (localUrl === "" || localUrl === "-") {
          this.playUrl = this.videoUrl;
        } else {
          this.playUrl = localUrl;
        }
      }else{
        this.playUrl = this.videoUrl;
      }

      this.initVideo();
    },

    handleSign() {
      this.$router.push({
        path: "/layout/signIn",
        query: { id: this.$route.query.id },
      });

      // 保存下一级路径
      const path = `/layout/signIn?id=${this.$route.query.id}`;
      this.useRouterList.push({
        name: "学员签到",
        path: path,
      });
      this.setRouterList(this.useRouterList, path);
    },
  },
  mounted() {
    this.userAgent = BrowserType();

    // window.addEventListener('load', function () {
    //   var refresh = document.getElementsByClassName('xgplayer-error-refresh')
    //   console.log(refresh)
    //   refresh[0].addEventListener('click', function () {
    //       console.log('sdfsdf')
    //     this.player.reload()
    //   })
    // });
  },
};
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.box {
  .content {
    min-height: 13rem;
    padding: 0.6rem 0.6rem 0 0.6rem;
    box-sizing: border-box;
    @include background_color("background-medium");
    display: flex;

    .content-left {
      .video-box {
        width: 6.6rem;
        height: 8.8rem;
        overflow: scroll;
      }

      .video-list {
        width: 7rem;
        height: 9.6rem;
        @include background_color("background-normal");
        padding: 0.4rem 0.2rem;
        box-sizing: border-box;
        overflow: hidden;

        .video-item {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 6.6rem;
          height: 1rem;
          padding: 0 0.4rem;
          color: #ffffff;
          font-size: 0.3rem;
          margin-bottom: 0.2rem;

          .video-item-left {
            display: flex;
            align-items: center;

            img {
              margin-right: 0.2rem;
            }
          }

          .time {
            font-size: 0.24rem;
          }
          .wacth{
            font-size: 0.1rem;
          }
        }

        .blur-item {
          @include background_color("background-medium");
        }

        .active-item {
          background-color: #cca766;
        }
      }

      .signin-btn {
        width: 7rem;
        height: 1.2rem;
        text-align: center;
        line-height: 1.2rem;
        background: #cca766;
        border-radius: 0.05rem;
        font-size: 0.46rem;
        color: #ffffff;
        margin-top: 0.5rem;
      }
    }

    .content-right {
      flex: 1;
      margin-left: 0.6rem;

      .title {
        display: flex;
        justify-content: space-between;
        font-size: 0.48rem;
        color: #ffffff;
        line-height: 0.48rem;

        .time {
          font-size: 0.36rem;
        }
      }

      .video {
        width: 16.8rem;
        height: 8.52rem;
        margin-top: 0.58rem;
      }
      .introduction {
        margin-top: 0.4rem;
        text-align: left;
        font-size: 0.28rem;
        color: #ffffff;
        height: 1.2rem;
        overflow: hidden;
      }
    }
  }
}
.back-group {
  z-index: 3005;
  position: fixed;
  right: 0.6rem;
  bottom: 0.8rem;
  /*opacity: 0.8;*/
  .btn-group {
    div {
      margin-bottom: 0.1rem;
    }
  }
  img {
    width: 1.2rem;
  }
}
</style>
