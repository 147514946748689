<template>
  <div class="box" :style="bg">
    <nav-router :routerList="routerList">
      <img :src="icon" alt="" style="width: 0.16rem;height: 0.16rem" />
    </nav-router>
    <div class="content">
      <img src="@/assets/images/dangHistory/book-open.png" alt="" />
      <div class="page1">
        {{ books[0].texts }}
      </div>
      <div class="page2">
        {{ page2Text }}
      </div>
      <div class="up">
        <img :src="up" alt="" />
        上一页
      </div>
      <div class="down">
        <img :src="down" alt="" />
        下一页
      </div>
    </div>
    <back :pageDepth="3"></back>
  </div>
</template>
<script>
import navRouter from "@/components/navRouter";
import back from "@/components/back";
import { API_MODULE } from "@/request/api";

const redIcon = require("@/assets/images/dangHistory/home.png");
const blueIcon = require("@/assets/images/dangHistory/home-blue.png");

const blueBg = require("@/assets/images/index/bg-blue.png");
const redBg = require("@/assets/images/index/bg.png");

const redUp = require("@/assets/images/dangHistory/up.png");
const redDown = require("@/assets/images/dangHistory/down.png");

const blueUp = require("@/assets/images/dangHistory/up-blue.png");
const blueDown = require("@/assets/images/dangHistory/down-blue.png");
import { mapGetters } from "vuex";

export default {
  name: "read",
  components: {
    navRouter,
    back,
  },
  data() {
    return {
      icon: redIcon,
      up: redUp,
      down: redDown,
      bg: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgRed: {
        backgroundImage: "url(" + redBg + ")",
      },
      bgBlue: {
        backgroundImage: "url(" + blueBg + ")",
      },
      routerList: [
        {
          name: "首页",
          path: "/layout/index",
        },
        {
          name: "党史馆",
          path: "/layout/dangHistory",
        },
        {
          name: "党的十八届三中全会",
          path: "",
        },
      ],
      books: [],
    };
  },
  computed: {
    ...mapGetters("skin", ["useSubject"]),
  },
  created() {
    if (this.useSubject === "red") {
      this.icon = redIcon;
      this.bg = this.bgRed;
      this.up = redUp;
      this.down = redDown;
    } else {
      this.icon = blueIcon;
      this.bg = this.bgBlue;
      this.up = blueUp;
      this.down = blueDown;
    }
    this.getDirectoryOrContent();
  },
  watch: {
    useSubject(val) {
      if (val === "red") {
        this.icon = redIcon;
        this.bg = this.bgRed;
        this.up = redUp;
        this.down = redDown;
      } else {
        this.icon = blueIcon;
        this.bg = this.bgBlue;
        this.up = blueUp;
        this.down = blueDown;
      }
    },
  },
  methods: {
    // 获取文章内容
    getDirectoryOrContent() {
      this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
        catId: [this.$route.query.id],
      })
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.books = res.data.model;
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/themes/_handle.scss";

.box {
  min-height: 13.8rem;
  overflow: hidden;
  .content {
    position: relative;
    width: 16.04rem;
    height: 12.24rem;
    font-size: 0.28rem;
    font-weight: bold;
    color: #555555;
    opacity: 1;
    margin: 0.6rem auto 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    .page1 {
      position: absolute;
      top: 0.9rem;
      left: 1.26rem;
      width: 6rem;
      height: 5rem;
      text-align: justify;
      /*background-color: red;*/
      /*color: #ffffff;*/
    }

    .page2 {
      position: absolute;
      top: 0.9rem;
      left: 1.26rem;
      width: 6rem;
      height: 5rem;
      text-align: justify;
      /*background-color: red;*/
      /*color: #ffffff;*/
    }

    .up {
      position: absolute;
      left: 1.26rem;
      bottom: 1.6rem;
    }

    .down {
      position: absolute;
      right: 1.26rem;
      bottom: 1.6rem;
    }
  }
}
</style>
