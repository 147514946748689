<template>
  <div class="box">
    <nav-router :routerList="routerList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="swiper-1" v-if="showSwiper">
        <el-carousel
          height="8.3rem"
          style="width: 21.8rem"
          indicator-position="none"
          :autoplay="autoPlay"
          :interval="interval"
          :loop="loop"
          :type="type === 1 ? 'card' : ''"
          @change="changeSwiper"
          ref="banner"
        >
          <el-carousel-item v-for="(item, index) in photoList" :key="index">
            <img :src="`${OSS_URL}${item.pictureVideo}`" :alt="item.name" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="btns">
        <div @click="openPhotoList()">
          <img :src="menu" alt="菜单" />
        </div>

        <div>
          <img :src="audio" alt="声音" @click="handleMusic(music)" />
        </div>
        <div>
          <img :src="play" alt="播放" @click="autoPlay = !autoPlay" />
        </div>
      </div>
      <div class="thumbnail" ref="wrapperBox">
        <div class="cont" ref="cont">
          <div
            v-for="(item, index) in photoList"
            :class="'item' + index"
            @click="selectPhoto('item' + index, index)"
            :key="index"
          >
            <img :src="`${OSS_URL}${item.pictureVideo}`" :alt="item.name" />
          </div>
        </div>
        <div
          :class="['setting-area', showSettingArea ? 'setting-area-show' : '']"
        >
          <div class="setting-list" v-if="showSettingArea">
            <!-- <div class="setting-item">
              <p class="title">主题</p>
              <el-select v-model="type" placeholder="请选择" class="select" size="medium" @change="changeSwiperMode">
                <el-option v-for="item in subjectOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div> -->
            <!-- <div class="setting-item">
                            <p class="title">音乐</p>
                            <el-select v-model="musicUrl" placeholder="请选择" class="select" size="medium">
                                <el-option
                                        v-for="item in musicOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div> -->
            <div class="setting-item">
              <p class="title">重复播放</p>
              <el-select
                v-model="loop"
                placeholder="请选择"
                class="select"
                size="medium"
              >
                <el-option
                  v-for="item in loopOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="setting-item">
              <p class="title">变化速度</p>
              <el-select
                v-model="interval"
                placeholder="请选择"
                class="select"
                size="medium"
              >
                <el-option
                  v-for="item in speedOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="setting-btn" @click="showSettingArea = !showSettingArea">
            <img src="@/assets/images/photo/setting.png" alt="" />
            <span class="setting-txt">{{
              showSettingArea ? "完成设置" : "设置选项"
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <back :pageDepth="4" style="bottom: 4rem; right: 0.4rem"></back>
  </div>
</template>

<script>
import BScroll from 'better-scroll'
import navRouter from '@/components/navRouter'
import back from '@/components/back'
import { API_MODULE } from '@/request/api'

const redMenu = require('@/assets/images/photo/menu.png')
const redPlay = require('@/assets/images/photo/play.png')
const redAudio = require('@/assets/images/photo/audio.png')

const blueMenu = require('@/assets/images/photo/menu-blue.png')
const bluePlay = require('@/assets/images/photo/play-blue.png')
const blueAudio = require('@/assets/images/photo/audio-blue.png')

const redIcon = require('@/assets/images/dangHistory/home.png')
const blueIcon = require('@/assets/images/dangHistory/home-blue.png')
const redPauseAudio = require('@/assets/images/photo/audio-pause.png')
const bluePauseAudio = require('@/assets/images/photo/audio-pause-blue.png')
import { mapGetters } from 'vuex'

export default {
  name: 'playMode',
  components: {
    navRouter,
    back
  },
  data() {
    return {
      menu: redMenu,
      play: redPlay,
      audio: redAudio,
      icon: redIcon,
      routerList: [
        {
          name: '党建风采',
          path: '/layout/photos'
        }
      ],
      showSwiper: true,
      photoList: [],
      scroll: null,
      title: '',
      playIndex: 0, //当前展示的照片下标
      autoPlay: true,
      interval: 5000,
      loop: true,
      subjectvalue: 0,
      showSettingArea: false,
      type: 0,
      nowIndex: 0, //实时记录的当前轮播下标位置，每次切换时都会更新
      subjectOptions: [
        {
          value: 0,
          label: '普通'
        },
        {
          value: 1,
          label: '卡片模式'
        }
      ],
      musicUrl: 'http://www.baidu.cn',
      musicOptions: [
        {
          value: 'http://www.baidu.cn',
          label: '义勇军进行曲'
        },
        {
          value: 'http://www.baidu.com',
          label: '黄河大合唱'
        }
      ],
      loopOptions: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      speedOptions: [
        {
          value: 3000,
          label: '3S'
        },
        {
          value: 4000,
          label: '4S'
        },
        {
          value: 5000,
          label: '5S'
        },
        {
          value: 8000,
          label: '8S'
        },
        {
          value: 10000,
          label: '10S'
        },
        {
          value: 30000,
          label: '30S'
        }
      ],
      music: undefined,
      isPause: false
    }
  },
  computed: {
    ...mapGetters('skin', ['useSubject'])
  },
  created() {
    if (this.useSubject === 'red') {
      this.menu = redMenu
      this.play = redPlay
      this.audio = redAudio
      this.icon = redIcon
    } else {
      this.menu = blueMenu
      this.play = bluePlay
      this.audio = blueAudio
      this.icon = blueIcon
    }
    this.POST(API_MODULE.SEARCH_CATEGORY_OR_CONTENT_BY_USER, {
      catId: [this.$route.query.catId]
    })
      .then((res) => {
        if (res.code == 0 || res.code == 200) {
          this.photoList = res.data.model
        } else {
          this.$message(res.msg)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    this.music = this.$route.query.music
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.menu = redMenu
        this.play = redPlay
        this.audio = redAudio
        this.icon = redIcon
      } else {
        this.menu = blueMenu
        this.play = bluePlay
        this.audio = blueAudio
        this.icon = blueIcon
      }
    },
    isPause(val) {
      if (this.useSubject === "red") {
        this.audio = val ? redAudio : redPauseAudio
      } else {
        this.audio = val ? blueAudio : bluePauseAudio
      }
    }
  },
  methods: {
    selectPhoto(clas, index) {
      let dom = document.getElementsByClassName(clas)[0]
      this.scroll.scrollToElement(dom, 1000, true) //滚动到点击区域，使点击的元素居中
      this.$refs.banner.setActiveItem(index)
    },
    scrollToIndexPhoto() {
      //初始化时滚动到目标位置
      let dom = document.getElementsByClassName('item' + this.playIndex)[0]
      this.scroll.scrollToElement(dom, 1000, true) //滚动到点击区域，使点击的元素居中
      this.$refs.banner.setActiveItem(this.playIndex) //banner 滚动到目标区域
    },
    changeSwiper(index) {
      //幻灯片切换时的回调
      this.nowIndex = index
      let dom = document.getElementsByClassName('item' + index)[0]
      this.scroll.scrollToElement(dom, 1000, true) //滚动到点击区域，使点击的元素居中
    },
    changeSwiperMode() {
      this.showSwiper = false

      //延时渲染 另一个模式
      let t = setTimeout(() => {
        this.showSwiper = true
        clearTimeout(t)
      }, 100)

      //banner 滚动到切换之前的那张图的位置
      let t2 = setTimeout(() => {
        this.$refs.banner.setActiveItem(this.nowIndex)
        clearTimeout(t2)
      }, 200)
    },
    openPhotoList() {
      this.$router.push({
        path: '/layout/photoList',
        query: { id: this.$route.query.catId, title: this.title }
      })
    },
    handleMusic(src) {
      this.isPause = !this.isPause
      if (src) {
        this.playOrPausedAudio(this.OSS_URL + src)
      } else {
        this.$message({ message: '请联系管理员上传音频', type: 'error' })
      }
    }
  },
  mounted() {
    this.title = this.$route.query.title
    this.$set(this.routerList, 1, { name: this.title, path: '' })
    this.playIndex = this.$route.query.index
    let width = this.photoList.length * 420 + 60 // 动态计算出滚动区域的大小(单个图片宽度3.6rem+0.6rem外边距)+父元素内边距，产生滚动的原因是滚动区域宽度大于父盒子宽度
    this.$refs.cont.style.width = width + 'px' // 修改滚动区域的宽度
    this.$nextTick(() => {
      this.scroll = new BScroll(this.$refs.wrapperBox, {
        startX: 0, // 配置的详细信息请参考better-scroll的官方文档，这里不再赘述
        click: true,
        scrollX: true,
        scrollY: false,
        eventPassthrough: 'vertical'
      })
      this.scrollToIndexPhoto()
    })
  },
  destroyed() {
    this.destroyAudio()
  }
}
</script>

<style scoped lang="scss">
@import "@/themes/_handle.scss";

.box {
  .router-nav {
    width: 100%;
    height: 0.6rem;
    background-color: #ffffff;
    padding-left: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .nav-left {
      display: flex;

      .router-name {
        font-size: 0.16rem;
        @include font_color("font-medium");
        margin-left: 0.1rem;

        .sub-router {
          font-size: 0.16rem;
          color: #cca766;
        }
      }
    }
  }

  .content {
    /*min-height: calc(100vh - 140px);*/
    height: 13rem;
    padding: 0.6rem 0 0 0;
    box-sizing: border-box;
    overflow: hidden;
    @include background_color("background-medium");

    .swiper-1 {
      width: 21.8rem;
      margin: 0 auto;
      background-color: #ffffff;

      img {
        /*width: 100%;*/
        height: 100%;
      }
    }

    .btns {
      position: fixed;
      top: 2rem;
      right: 0.68rem;

      div {
        margin-bottom: 0.6rem;

        img {
          width: 0.8rem;
          cursor: pointer;
        }
      }
    }
  }

  .thumbnail {
    height: 3.7rem;
    width: 25.6rem;
    overflow: hidden;
    position: relative;
    margin-top: 0.6rem;
    @include background_color("background-high");

    .cont {
      display: flex;
      padding: 0.55rem 0 0.55rem 0.6rem;

      img {
        width: 3.6rem;
        height: 2.6rem;
        margin-right: 0.6rem;
      }
    }

    .setting-area {
      width: 3rem;
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      @include background_color("background-high");
      display: flex;
      justify-content: space-between;
      transition: all 0.5s;

      .setting-list {
        display: flex;
        align-items: center;

        .setting-item {
          text-align: left;
          margin-left: 0.6rem;

          .title {
            font-size: 0.36rem;
            color: #ffffff;
          }

          .select {
            width: 4rem;
            margin-top: 0.18rem;
          }
        }
      }

      .setting-btn {
        background-color: #cca766;
        height: 100%;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .setting-txt {
          display: inline-block;
          font-size: 0.36rem;
          color: #ffffff;
          margin-left: 0.1rem;
        }
      }
    }

    .setting-area-show {
      width: 100% !important;
      transition: all 0.5s;
    }
  }
}
</style>
<style lang="scss">
@import "@/themes/_handle.scss";

.el-select .el-input.is-focus .el-input__inner {
  border-color: transparent;
}

.el-select-dropdown__item.selected {
  @include font_color("font-medium");
}

.el-input--medium .el-input__inner {
  font-size: 0.36rem;
  height: 0.8rem;
  /*color: transparent;*/
  opacity: 0.8;
}

.el-select-dropdown__item {
  height: 0.6rem;
  line-height: 0.6rem;
  font-size: 0.2rem;
}

.el-select .el-input .el-select__caret {
  font-size: 0.36rem;
  line-height: 0.72rem;
}
</style>
