var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",style:(_vm.boxBg)},[_c('nav-router',{attrs:{"routerList":_vm.useRouterList}},[_c('img',{attrs:{"src":_vm.icon,"alt":""}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"tabs"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,class:[
          'tab-item',
          _vm.activeIndex === index ? 'active-tab' : 'blur-item',
        ],on:{"click":function($event){return _vm.changeTabs(index)}}},[_c('span',[_vm._v(_vm._s(item))])])}),0),_c('div',{staticClass:"tab-container"},[_vm._l((_vm.courseList),function(course){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex !== 3),expression:"activeIndex !== 3"}],key:course.id,staticClass:"course-item",on:{"click":function($event){return _vm.$router.push({
            path: `/layout/learn/${course.id}`,
          })}}},[_c('div',{staticClass:"cover"},[_c('img',{attrs:{"src":`${_vm.OSS_URL}${course.picture}`,"alt":""}})]),_c('div',{staticClass:"course-introduction"},[_c('p',{staticClass:"course-name"},[_vm._v(_vm._s(course.name))]),_c('div',{staticClass:"course-percentage"},[_c('el-progress',{attrs:{"percentage":parseInt(course.percentage),"show-text":false,"stroke-width":14}})],1),_c('div',{staticClass:"course-footer"},[_c('p',[_vm._v(" 总时长:"+_vm._s(course.durationTotal ? course.durationTotal : 0)+" ")]),_c('p',[_vm._v("已学习"+_vm._s(course.percentage ? course.percentage : 0)+"%")])])])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === 3),expression:"activeIndex === 3"}],staticClass:"analysis"},[_c('div',{staticClass:"years"},_vm._l((_vm.years),function(item){return _c('div',{key:item,class:[
              'year',
              _vm.activeYear == item ? 'active-year' : 'blur-year',
            ],on:{"click":function($event){return _vm.changeYear(item)}}},[_c('span',[_vm._v(_vm._s(item))])])}),0),_c('div',{staticClass:"year-contaner",style:(_vm.bg)},[_c('div',{staticClass:"year-contaner-column"},[_c('div',{staticClass:"block"},[_c('div',[_vm._v(_vm._s(_vm.yearStatistical.chapterCount))]),_c('div',[_vm._v("总章节")])]),_c('div',{staticClass:"block"},[_c('div',[_vm._v(_vm._s(_vm.yearStatistical.accomplishCount))]),_c('div',[_vm._v("完成章节")])])]),_c('div',{staticClass:"year-contaner-column"},[_c('div',{staticClass:"block"},[_c('div',[_vm._v(_vm._s(_vm.yearStatistical.picture)+"%")]),_c('div',[_vm._v("完成率")])]),_c('div',{staticClass:"block"},[_c('div',[_vm._v(_vm._s(_vm.yearStatistical.signinCount))]),_c('div',[_vm._v("签到数")])])])])])],2)]),_c('menu-pop',{attrs:{"activeItem":3}}),_c('back',{attrs:{"pageDepth":3}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }